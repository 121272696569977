import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { openNotification } from "../../functions";
import { NavBar } from "../../components/navBar";
import { Card, Space, Table, Typography, Row, Col } from 'antd';
import { Column, Pie } from '@ant-design/charts';

const { Title } = Typography;

export default class CommerceStat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      statsData: null,
      statsDescData: null
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  ChangeSelect(value) {
    // this.setState({ newUserRole: value });
  }

  getStat() {
    fetch(`https://student-control.nti-center.ru/api/commerce-stat`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          // Данные для таблицы статистики по датам
          const statsData = Object.entries(response.stats).map(([date, { count, totalAmount }]) => ({
            date,
            count,
            totalAmount,
          }));
        
          // Данные для графика по типам подписок
          const statsDescData = Object.entries(response.statsDesc).map(([description, { count, totalAmount }]) => ({
              description,
              count,
              totalAmount,
          }));

          this.setState({ stats: response, statsDescData: statsDescData, statsData: statsData })
        }
      })
  }

  render() {
    // Колонки для таблицы статистики по датам
    const statsColumns = [
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Количество операций',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: 'Общая сумма',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
      },
    ];



    // Колонки для таблицы статистики по типам подписок
    const statsDescColumns = [
      {
        title: 'Тип подписки',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Количество операций',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: 'Общая сумма',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
      },
    ];



    // Колонки для таблицы аномалий
    const anomaliesColumns = [
      {
        title: 'ID операции',
        dataIndex: 'operation_id',
        key: 'operation_id',
      },
      {
        title: 'Описание',
        dataIndex: 'operation_desc',
        key: 'operation_desc',
      },
      {
        title: 'Цена',
        dataIndex: 'operation_price',
        key: 'operation_price',
      },
      {
        title: 'Дата',
        dataIndex: 'operation_date',
        key: 'operation_date',
      },
    ];

    // const statsConfig = {
   
    //   xField: 'date',
    //   yField: 'totalAmount',
    //   label: {
    //       position: 'inside', // Исправлено: 'middle' -> 'inside'
    //       style: {
    //           fill: '#FFFFFF',
    //           opacity: 0.6,
    //       },
    //   },
    //   xAxis: {
    //       label: {
    //           autoRotate: false,
    //       },
    //   },
    //   slider: {
    //       start: 0.1,
    //       end: 0.2,
    //   },
    // };
    const statsConfig = {
      data: this.state.statsData,
      xField: 'date',
      yField: 'totalAmount',
      label: {
          position: 'inside',
          style: {
              fill: '#FFFFFF',
              opacity: 0.6,
          },
      },
      xAxis: {
          label: {
              autoRotate: false,
          },
      },
      slider: {
        x: {
          values: [0.0, 1],
        },
      },
    };

    const statsConfig2 = {
      data: this.state.statsData,
      xField: 'date',
      yField: 'count',
      label: {
          position: 'inside',
          style: {
              fill: '#FFFFFF',
              opacity: 0.6,
          },
      },
      xAxis: {
          label: {
              autoRotate: false,
          },
      },
      slider: {
        x: {
          values: [0.0, 1],
        },
      },
    };

  // Конфигурация графика по типам подписок (круговая диаграмма)
  const statsDescConfig = {
      data: this.state.statsDescData,
      angleField: 'count',
      colorField: 'description',
      radius: 0.8,
      label: {
          // type: 'outer', // Исправлено: 'outer' -> 'spider'
          // content: '{name} {percentage}',
          text: "count"
      },
      interactions: [{ type: 'element-active' }],
  };

    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Статистика коммерции</div>
          {
            this.state.stats == null ?
              ""
              :
              <div>
                 <Row gutter={16} style={{ marginBottom: '24px' }}>
                    <Col span={8}>
                        <Card title="Всего операций" bordered={false}>
                            <Title level={2}>{this.state.stats.totalOperations}</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Общая сумма" bordered={false}>
                            <Title level={2}>{this.state.stats.totalAmount} руб.</Title>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Средняя цена" bordered={false}>
                            <Title level={2}>{this.state.stats.averagePrice.toFixed(2)} руб.</Title>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: '24px' }}>
                    <Col span={12}>
                        <Card title="Статистика сумм по датам">
                            <Column {...statsConfig} />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Статистика операций по датам">
                            <Column {...statsConfig2} />
                        </Card>
                    </Col>
                   
                </Row>

                <Row gutter={16} style={{ marginBottom: '24px' }}>
                   <Col span={12}>
                        <Card title="Статистика по типам подписок">
                            <Pie {...statsDescConfig} />
                        </Card>
                    </Col>
                    <Col span={12}>
                      {Object.entries(this.state.stats.statsDesc).map(([description, { totalAmount }]) => (
                        <Col key={description} style={{ marginBottom: '24px' }}>
                            <Card title={description} bordered={false}>
                                <Title level={3}>{totalAmount} руб.</Title>
                            </Card>
                        </Col>
                    ))}
                    </Col>
                </Row>

                {/* Аномалии */}
                <Card title="Аномалии">
                  <Table
                    columns={anomaliesColumns}
                    dataSource={this.state.stats.anomalies}
                    pagination={false}
                  />
                </Card>
              </div>
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getStat();
  }

}