import React, { Fragment } from "react";
// import config from '../config.json';
import { openNotification } from "../../functions";
import { Drawer, Space, Button, Modal, Result, Progress, Tabs, Card, Tooltip, Input, Spin, Collapse, Table } from 'antd';
import {
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FileTextOutlined,
  FileDoneOutlined,
  OrderedListOutlined,
  RedoOutlined
} from '@ant-design/icons';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { NavBar } from "../../components/navBar";
import { withRouter } from '../../components/withRouter';
import CurseMaterialRender from "../../components/CurseMaterialRender";

import moment from 'moment';
import TextArea from "antd/es/input/TextArea";

class TeacherCourse extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      resultsList: [],
      course: [],
      course_id: "",
      homeWorkModal: false,
      homeWork: [],
      homeWorkQuestions: [],
      homeWorkType: "",
      homeWorkName: "",
      homeWorkId: "",
      homeWorkNeedAnswer: 0,
      homeWorkAnswer: [],
      homeWorkReturn: false,

      modules: [],
      moduleId: 0,
      moduleName: "",

      lessons: [],
      lessonsModal: false,
      lessonName: "",
      lessonId: "",
      lessonVideos: "",
      lessonBody: "",
      lessonNeedAnswer: "",
      lessonType: "",
      lessonComment: "",
      modal: false,
      additionalMaterials: []
    };
  }

  getLessonsAdditional(module, lesson) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/${this.state.course.sc_id}/module/${module}/lesson/${lesson}/additionalMaterials`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          this.setState({
            additionalMaterials: []
          });
        } else {
          this.setState({
            additionalMaterials: response.data
          });
        }
      })
  }

  getCourse() {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + window.location.href.split('/course/')[1], {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
          this.props.navigate('/teacher/');
        } else {
          this.setState({ course: response.data[0] });
          this.getResults(response.data[0].sc_id);
          this.getCourseModules(response.data[0].sc_id);
        }
      })
  }

  getCourseModules(course) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/${course}/modules`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ modules: response.data })
        }
      })
  }

  getResults(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + id + "/results", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {

        } else {
          let arr = [];

          response.data.forEach((element, index) => {
            arr.push({
              key: "data" + index,
              fio: element.client_surname + " " + element.client_name + " " + element.client_patronymic,
              date: element.scr_date,
              name: element.scl_name + " (" + element.scl_type + ")",
              events: <Tooltip title="Смотреть работу"><EyeOutlined onClick={() => { this.getResult(element.scr_id) }} /></Tooltip>
            })
          });

          this.setState({ resultsList: arr });
        }
      })
  }

  getResult(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + this.state.course.sc_id + "/results/" + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            homeWorkQuestions: stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))) && stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))).replace(/href/g, `target='_blank' href`),
            homeWorkType: response.data[0].scl_type,
            homeWorkName: response.data[0].scl_name,
            homeWorkId: response.data[0].scr_id,
            homeWorkComment: response.data[0].sc_comment,
            homeWorkModal: true,
            homeWorkContent: response.data[0].sc_content,
            homeWork: response.data[0]
          })
        }
      })
  }

  sendResult(btn) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + this.state.course.sc_id + "/results/" + this.state.homeWorkId + "/send", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        comment: this.state.homeWorkComment,
        checked: btn
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({
            homeWorkQuestions: "",
            homeWorkType: "",
            homeWorkName: "",
            homeWorkId: "",
            homeWorkComment: "",
            homeWorkModal: false,
            homeWorkContent: "",
            homeWork: ""
          });
          this.getCourse();
        }
      })
  }

  getLessonsOnModules(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/${this.state.course.sc_id}/module/${id}/lessons`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            lessons: response.data,
            lessonsModal: true
          })
        }
      })
  }

  getLesson(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/${this.state.course.sc_id}/module/${this.state.moduleId}/lesson/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            lessonName: response.data[0].scl_name,
            lessonId: response.data[0].scl_id,
            lessonVideos: response.data[0].scl_videos ? response.data[0].scl_videos : "",
            // lessonBody: response.data[0].scl_type == 'Урок' || response.data[0].scl_type == "Текстовое задание" ? response.data[0].scl_body ? stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))) && stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))).replace(/href/g, `target='_blank' href`) : "" : JSON.parse(response.data[0].scl_body),
            lessonNeedAnswer: response.data[0].scl_need_answer,
            lessonType: response.data[0].scl_type,
            lessonComment: response.data[0].sc_comment,
            modal: true
          });

          if (response.data[0].scl_body !=="") {
            this.setState({ lessonBody: response.data[0].scl_body })
          } else {
            this.setState({ lessonBody: "" })
          }

          this.getLessonsAdditional(this.state.moduleId, id);
        }
      })
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    const columns = [
      {
        title: 'ФИО',
        dataIndex: 'fio',
        key: 'fio'
      },
      {
        title: 'Дата сдачи задания',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text, record) => {
          return (
            moment(text).format('DD-MM-YYYY')
          )
        }
      },
      {
        title: 'Название задания',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];
    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="big_course_name">Курс: {this.state.course.sc_name}</div>
          <h3 style={{ marginTop: 30 }}>Задания на проверку</h3>

          <Table columns={columns} dataSource={this.state.resultsList} />

          <h3 style={{ marginTop: 30 }}>Содержание курса</h3>

          {
            this.state.modules.length == 0 ? ""
              :
              <div className="lessons_grid">
                {

                  this.state.modules.map((module, index) => {
                    return (
                      <div className="module_block" key={"mb" + index} style={{ cursor: 'pointer' }} onClick={() => { this.getLessonsOnModules(module.scm_id); this.setState({ moduleName: module.scm_name, moduleId: module.scm_id }) }}>
                        <div className="name">{module.scm_name}</div>
                      </div>
                    )
                  }
                  )
                }

              </div>
          }

          <Modal title={this.state.moduleName} open={this.state.lessonsModal} footer={[<Button onClick={() => { this.setState({ lessonsModal: false }) }}>Закрыть</Button>]} onCancel={() => { this.setState({ lessonsModal: false }) }}>
            {
              this.state.lessons.length == 0 ? "Не найдено ни одного урока"
                :
                this.state.lessons.map((lesson, index) => {
                  return (
                    <div className="lesson_list_item" style={{ cursor: lesson.scl_type == "Урок" || (lesson.sc_result == null) || lesson.sc_checked == 2 ? 'pointer' : "no-drop" }} key={"less_" + index} onClick={() => { this.getLesson(lesson.scl_id) }}>
                      <Tooltip key={"lesst_" + index} title={lesson.sc_result == "-1" ? lesson.sc_checked == 1 ? "На проверке" : lesson.sc_checked == 2 ? "Не сдал" : "Просмотрено" : lesson.scr_passed == 1 ? "Тест сдан" : lesson.scr_passed == 0 ? "Тест провален" : "Не пройдено"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill={lesson.sc_checked == 0 ? "#b6ec3d" : lesson.sc_checked == 1 ? "var(--accent)" : lesson.sc_checked == 2 ? "red" : "black"}>
                          <path d="M1.92309 12.5016C1.9228 10.6448 2.41131 8.8207 3.33951 7.21258C4.26771 5.60445 5.60289 4.26901 7.21083 3.3405C8.81878 2.41198 10.6428 1.92312 12.4996 1.92304C14.3564 1.92297 16.1805 2.41169 17.7885 3.34008C18.0092 3.46558 18.2706 3.49867 18.5156 3.43215C18.7607 3.36562 18.9695 3.20487 19.0964 2.98498C19.2234 2.76509 19.2582 2.50391 19.1933 2.25843C19.1284 2.01295 18.969 1.80311 18.75 1.6747C16.367 0.298895 13.5967 -0.252182 10.8686 0.106931C8.14056 0.466044 5.60721 1.71528 3.66146 3.6609C1.71572 5.60651 0.466324 8.13978 0.107035 10.8678C-0.252253 13.5959 0.298647 16.3663 1.6743 18.7493C3.04995 21.1324 5.17347 22.9949 7.71554 24.0481C10.2576 25.1013 13.0762 25.2863 15.7341 24.5744C18.392 23.8625 20.7407 22.2934 22.4161 20.1106C24.0914 17.9278 24.9996 15.2532 25 12.5016C25 12.2466 24.8987 12.002 24.7184 11.8217C24.538 11.6414 24.2935 11.5401 24.0385 11.5401C23.7834 11.5401 23.5389 11.6414 23.3586 11.8217C23.1782 12.002 23.0769 12.2466 23.0769 12.5016C23.0769 15.3068 21.9626 17.9971 19.979 19.9806C17.9955 21.9642 15.3052 23.0785 12.5 23.0785C9.69484 23.0785 7.00456 21.9642 5.021 19.9806C3.03744 17.9971 1.92309 15.3068 1.92309 12.5016Z" />
                          <path d="M26.7201 3.60213C26.8088 3.51498 26.8792 3.41152 26.9273 3.29766C26.9753 3.18379 27 3.06175 27 2.93851C27 2.81526 26.9753 2.69322 26.9273 2.57935C26.8792 2.46549 26.8088 2.36203 26.7201 2.27488C26.6314 2.18773 26.526 2.1186 26.4101 2.07144C26.2941 2.02428 26.1699 2 26.0444 2C25.9189 2 25.7946 2.02428 25.6786 2.07144C25.5627 2.1186 25.4574 2.18773 25.3686 2.27488L12.6823 14.7356L7.63138 9.77345C7.54264 9.6863 7.43729 9.61717 7.32135 9.57001C7.20541 9.52284 7.08114 9.49857 6.95564 9.49857C6.83015 9.49857 6.70588 9.52284 6.58993 9.57001C6.47399 9.61717 6.36864 9.6863 6.2799 9.77345C6.19116 9.8606 6.12077 9.96406 6.07274 10.0779C6.02472 10.1918 6 10.3138 6 10.4371C6 10.5603 6.02472 10.6824 6.07274 10.7962C6.12077 10.9101 6.19116 11.0136 6.2799 11.1007L12.0065 16.7246C12.0952 16.8119 12.2005 16.8812 12.3165 16.9284C12.4324 16.9757 12.5567 17 12.6823 17C12.8078 17 12.9321 16.9757 13.0481 16.9284C13.164 16.8812 13.2693 16.8119 13.358 16.7246L26.7201 3.60213Z" />
                        </svg>

                      </Tooltip>
                      <Tooltip key={"less_icon_" + index} title={lesson.scl_type}>
                        {
                          lesson.scl_type == "Урок" ?
                            <FileTextOutlined className="lesson_list_item_type" />
                            : ""
                        }
                        {
                          lesson.scl_type == "Текстовое задание" ?
                            <FileDoneOutlined className="lesson_list_item_type" />
                            : ""
                        }
                        {
                          lesson.scl_type == "Тест" ?
                            <OrderedListOutlined className="lesson_list_item_type" />
                            : ""
                        }
                      </Tooltip>
                      <div key={"less_text_" + index} style={{ width: 'auto' }}>
                        {lesson.scl_name}
                      </div>
                    </div>
                  )
                })
            }
          </Modal>
        </div>
        <Drawer
            width={window.innerWidth}
            title={this.state.lessonName}
            placement="right"
            onClose={() => { this.setState({ modal: false }) }}
            open={this.state.modal}
            closeIcon={null}
            extra={
              <Space>
                <Button onClick={() => { this.setState({ modal: false });  }}>Закрыть</Button>
              </Space>
            }
          >
            <div>
              {
                this.state.lessonVideos == "" ? [] :
                  <div>
                    {
                      this.state.lessonVideos == "" ? ""
                        :
                        <div>
                          <h3>Видео урока</h3>
                          <div className="myLessonsVideos" dangerouslySetInnerHTML={{ __html: this.state.lessonVideos }}></div>
                        </div>
                    }
                  </div>
              }

              {
                this.state.additionalMaterials.length == 0 ? [] :
                  <div>
                    <h3 style={{ marginTop: 30 }}>Дополнительные материалы</h3>
                    {
                      this.state.additionalMaterials.map((elem, index) => {
                        if (elem.scam_type == 'Файл') {
                          return (
                            <a href={elem.scam_content} target="_blank" className="additionaMaterial_block" key={"am" + index}>{elem.scam_name}</a>
                          )
                        }

                      })
                    }
                  </div>
              }

              {
                this.state.lessonBody == "" ? [] :
                  this.state.lessonType == "Тест" ?
                    <div>
                      {this.state.lessonBody.map((question, index) => {
                        return (
                          <div className="question" key={index}>
                            <div className="questionName">{question.title}</div>
                            <div>{question.variants.map((variant, index2) => {
                              return (
                                <div key={index + ":" + index2}>
                                  <input type="radio" name={index} onClick={() => { this.addAnswer(index, index2); }} />
                                  <label htmlFor={index}>{variant.title}</label>
                                </div>
                              )
                            })}</div>
                          </div>
                        )
                      })}
                    </div>
                    :
                    <div>
                      <h3 style={{marginTop: 30}}>Материал</h3>
                      <CurseMaterialRender content={JSON.parse(this.state.lessonBody)} />
                    </div>
              }

              {
                this.state.lessonNeedAnswer == 1 ?
                  this.state.lessonType == "Тест" ?
                    <div style={{ background: "var(--accent)", padding: 15, borderRadius: 16, marginTop: 30 }}>
                      <h4 style={{ margin: 0, color: 'white' }}>Перед сохранением, убедитесь что вы ответили на все вопросы</h4>
                      <Button onClick={() => { this.saveAnswer() }}>Сохранить ответ</Button>
                    </div>
                    :
                    <div style={{ background: "var(--accent)", padding: 15, borderRadius: 16, marginTop: 30 }}>
                      <h4 style={{ margin: 0, color: 'white' }}>Дайте ответ на задание, в строке ниже</h4>
                      {
                        this.state.lessonComment == null || this.state.lessonComment == "" ?
                          ""
                          :
                          <p style={{ color: "white", fontSize: 16 }}>Комментарий от преподавателя: {this.state.lessonComment}</p>
                      }
                      <Input name="lessonAnswer" onChange={this.ChangeInputValue.bind(this)} value={this.state.lessonAnswer} style={{ margin: '10px 0' }} />
                      <Button onClick={() => { this.saveAnswer() }}>Сохранить ответ</Button>
                    </div>
                  :
                  ''
              }
            </div>

          </Drawer>
        <Drawer
          width={window.innerWidth}
          title={this.state.homeWork.client_surname + " " + this.state.homeWork.client_name + " " + this.state.homeWork.client_patronymic}
          placement="right"
          onClose={() => { this.setState({ homeWorkModal: false }) }}
          open={this.state.homeWorkModal}
          extra={
            <Space>
              <Button onClick={() => { this.setState({ homeWorkModal: false }); }}>Отмена</Button>
            </Space>
          }
        >
          <h3>Задание:</h3>
          <div dangerouslySetInnerHTML={{ __html: this.state.homeWorkQuestions }}></div>

          <h3 style={{ marginTop: 30 }}>Ответ слушателя:</h3>
          <div>{this.state.homeWorkContent}</div>

          <h3 style={{ marginTop: 30 }}>Ваш комментарий:<Tooltip title="В случае отправки на дорабоку вы можете оставить комментарий, этот текст будет виден слушателю во время доработки домашнего задания"><InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: '10px' }} /></Tooltip></h3>
          <TextArea rows={4} name="homeWorkComment" onChange={this.ChangeInputValue.bind(this)} value={this.state.homeWorkComment} />

          <Space style={{ marginTop: 30 }}>
            <Button onClick={() => { this.sendResult(0) }} type='primary'>Принять работу</Button>
            <Button onClick={() => { this.sendResult(2) }}>Отправить на доработку</Button>
          </Space>
        </Drawer>
      </div>
    );
  }

  componentDidMount() {
    this.getCourse();
  }
}

export default withRouter(TeacherCourse);