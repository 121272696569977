import React from "react";
import {
  EyeOutlined,
  DeleteOutlined,
  CheckCircleTwoTone,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseCircleTwoTone,
  SoundOutlined,
  TeamOutlined,
  DesktopOutlined,
  SettingOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import { Table, Button, Input, Popconfirm, Drawer, Space, Switch, Card, Modal, Tabs, Tooltip, Radio, Transfer, DatePicker, ConfigProvider, Checkbox, Upload, message } from 'antd';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
// import config from '../config.json';
import { openNotification } from "../../functions";
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, RichUtils, ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { NavBar } from "../../components/navBar";

import moment from 'moment';

const { TextArea } = Input;
const { Dragger } = Upload;

export default class StudentControlCourses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      courses: [],
      projects: [],
      edit: false,
      modules: [],
      courseId: "",
      courseName: "",
      courseDesc: "",
      courseProject: "",
      courseYear: "",
      editorStateS: EditorState.createEmpty(),
      editorStateLesson: "",
      editorStateHW: EditorState.createEmpty(),
      editorStateAM: EditorState.createEmpty(),
      lessons: [],
      editLesson: false,
      lessonType: "Урок",
      lessonName: "",
      lessonContent: "",
      lessonContentPassingScore: 0,
      lessonVideos: "",
      lessonNeedAnswer: false,
      lessonTurn: "",
      lessonId: null,
      lessonCourseId: null,
      homeWorks: [],
      hwObj: null,
      homeWorkModal: false,
      homeWorkName: "",
      homeWorkQuestions: [],
      homeWorkId: "",
      homeWorkType: "",
      homeWorkNeedAnswer: false,
      homeWorkPassingScore: 0,
      newModule: false,
      newModuleName: "",
      moduleName: "",
      moduleId: "",
      lessonsModal: false,
      newLessonModal: false,
      newLessonName: "",
      newLessonType: "Урок",
      teachers: [],
      activeTeachers: [],
      targetKeys: [],
      targetKeysTeachers: [],
      groups: [],
      createGroupModal: false,
      groupId: "",
      groupName: "",
      groupStartDate: "",
      groupStopDate: "",
      groupStatus: true,
      groupAutoStart: false,
      groupAutoStop: false,
      userListModal: false,
      userList: [],
      listeners: [],

      groupSettingId: "",
      groupSettingModal: false,
      groupSettingName: "",
      groupSetting_date_start: "",
      groupSetting_date_end: "",
      groupSetting_autostart: 0,
      groupSetting_autostop: 0,
      groupSetting_status: 0,

      additionalMaterialModal: false,
      additionalMaterialType: "Файл",
      additionalMaterialName: "",
      additionalMaterialContent: "",
      additionalMaterialLink: "",
      newCourseModal: "",
      additionalMaterials: [],

      ads_list: [],
      ads_name: "",
      ads_id: "",
      editorStateAdsContent: EditorState.createEmpty(),
      ads_group: "",
      ads_modal: false,
      addAds_modal: false,

      footPaintModal: false,
      footUserIds: [],
      resLessons: [],
      resultsByUser: {},
      resultsByUserDates: {}
    };
    this.onChangeEditor = editorStateS => this.setState({ editorStateS });
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.onChangeEditorLesson = editorStateLesson => { this.setState({ editorStateLesson }); console.log(editorStateLesson) };
    this.handleKeyCommandLesson = this.handleKeyCommandLesson.bind(this);
    this.onChangeEditorHW = editorStateHW => this.setState({ editorStateHW });
    this.handleKeyCommandHW = this.handleKeyCommandHW.bind(this);
    this.onChangeEditorAM = editorStateAM => this.setState({ editorStateAM });
    this.handleKeyCommandAM = this.handleKeyCommandAM.bind(this);
    this.onChangeEditorAdsContent = editorStateAdsContent => this.setState({ editorStateAdsContent });
    this.handleKeyCommandAdsContent = this.handleKeyCommandAdsContent.bind(this);
  }

  handleKeyCommandLesson(command, editorStateLesson) {
    const newState2 = RichUtils.handleKeyCommand(editorStateLesson, command);
    if (newState2) {
      this.onChangeEditorLesson(newState2);
      return 'handled';
    }
    return 'not-handled';
  }

  handleKeyCommandHW(command, editorStateHW) {
    const newState3 = RichUtils.handleKeyCommand(editorStateHW, command);
    if (newState3) {
      this.onChangeEditorHW(newState3);
      return 'handled';
    }
    return 'not-handled';
  }


  handleKeyCommand(command, editorStateS) {
    const newState = RichUtils.handleKeyCommand(editorStateS, command);
    if (newState) {
      this.onChangeEditor(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleKeyCommandAdsContent(command, editorStateAdsContent) {
    const newStateAdsContent = RichUtils.handleKeyCommand(editorStateAdsContent, command);
    if (newStateAdsContent) {
      this.onChangeEditorAdsContent(newStateAdsContent);
      return 'handled';
    }
    return 'not-handled';
  }

  handleKeyCommandAM(command, editorStateAM) {
    const newStateAM = RichUtils.handleKeyCommand(editorStateAM, command);
    if (newStateAM) {
      this.onChangeEditorAM(newStateAM);
      return 'handled';
    }
    return 'not-handled';
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/student-control`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((course) => {
            arr.push({
              key: course.sc_id,
              id: course.sc_id,
              name: course.sc_name,
              year: course.sc_year,
              project_name: course.sc_project,
              events: <div>
                <EyeOutlined onClick={() => {
                  this.getCourse(course.sc_id); this.getCourseModules(course.sc_id); this.getTeachers(); this.getGroups(course.sc_id); this.setState({ courseId: course.sc_id });
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить курс?"
                  okText="Да"
                  onConfirm={() => { this.deleteCourse(course.sc_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ courses: arr });
        }
      })
  }

  getCourse(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/` + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {

          this.setState({
            courseName: response.data[0].sc_name,
            courseProject: response.data[0].sc_project,
            courseYear: response.data[0].sc_year,
            editorStateS: !response.data[0].sc_desc ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(response.data[0].sc_desc))),
            courseDesc: response.data[0].sc_desc,
            courseId: response.data[0].sc_id,
            edit: true, modal: true,
          });
          this.getCourseTeachers(response.data[0].sc_id);
        }
      })
  }

  getCourseModules(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + id + `/modules`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            modules: response.data
          });
        }
      })
  }

  getLessonsOnModule(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/module/${id}/lessons`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ lessons: response.data, lessonsModal: true });
        }
      })
  }

  getLesson(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/module/${this.state.moduleId}/lesson/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            editorStateLesson: response.data[0].scl_type == "Тест" ? "" : response.data[0].scl_body == "" ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(response.data[0].scl_body))),
            lessonName: response.data[0].scl_name,
            lessonType: response.data[0].scl_type,
            lessonContent: response.data[0].scl_type == "Тест" ? response.data[0].scl_body.length == 0 ? [] : JSON.parse(response.data[0].scl_body) : response.data[0].scl_body,
            lessonTurn: response.data[0].scl_turn,
            lessonVideos: response.data[0].scl_videos,
            lessonNeedAnswer: response.data[0].scl_need_answer,
            lessonId: response.data[0].scl_id,
            editLesson: true
          });
        }
      })
  }


  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  deleteCourse(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache'
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', "Курс скрыт", 3, 4.5);
          this.getCourses();
        }
      })
  }

  saveLesson() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + '/lesson/' + this.state.lessonId, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        scl_name: this.state.lessonName,
        scl_body: this.state.lessonType == 'Тест' ? this.state.lessonContent : convertToRaw(this.state.editorStateLesson.getCurrentContent()).blocks[0].text == "" && convertToRaw(this.state.editorStateLesson.getCurrentContent()).blocks.length == 1 ? "" : convertToRaw(this.state.editorStateLesson.getCurrentContent()),
        scl_videos: this.state.lessonVideos,
        scl_passingScore: this.state.lessonContentPassingScore,
        scl_need_answer: this.state.lessonType == 'Тест' ? true : this.state.lessonNeedAnswer
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.getLessonsOnModule(this.state.moduleId)
          }
        }
      })
  }

  saveAboutCourse() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + '/about', {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        sc_name: this.state.courseName,
        sc_desc: convertToRaw(this.state.editorStateS.getCurrentContent()),
        sc_project: this.state.courseProject,
        sc_year: this.state.courseYear
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
          }
        }
      })
  }

  deleteLesson(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/lesson/` + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        scl_id: id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.getLessonsOnModule(this.state.moduleId);
          }
        }
      })
  }

  getHomeWork(lesson) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + '/lesson/' + lesson + '/homework', {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ homeWorks: response.data });
        }
      })
  }

  addNewModule() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/modules`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        scm_name: this.state.newModuleName
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({ newModule: false });
          this.getCourseModules(this.state.courseId);
        }
      })
  }

  deleteModule(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + id + `/modules`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        scm_id: id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.getCourseModules(this.state.courseId);
          }
        }
      })
  }

  addNewLesson() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/module/` + this.state.moduleId + `/lesson`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        scl_name: this.state.newLessonName.trim(),
        scl_type: this.state.newLessonType
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({ newLessonModal: false });
          this.getLessonsOnModule(this.state.moduleId);
        }
      })
  }



  sendAnswers() {
    let questionsBlock;
    if (this.state.homeWorkType == "Тест") {
      questionsBlock = this.state.homeWorkQuestions;
    }

    if (this.state.homeWorkType == "Письменный ответ") {
      questionsBlock = convertToRaw(this.state.editorStateHW.getCurrentContent());
    }

    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/module/` + this.state.moduleId + `/lesson/` + this.state.lessonId + `/homework/` + this.state.homeWorkId, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: this.state.homeWorkId == "new" ? "POST" : 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        type: this.state.homeWorkType,
        questions: questionsBlock,
        name: this.state.homeWorkName,
        needAnswer: this.state.homeWorkNeedAnswer,
        passingScore: Number(this.state.homeWorkPassingScore)
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.getHomeWork(this.state.lessonId);
        }
      })
  }

  onChangeTransfer = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  }

  onChangeTransferTeachers = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeysTeachers: nextTargetKeys });
  }

  getCourseTeachers(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/` + id + "/teachers", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let arr2 = [];
          response.data.map(elem => {
            arr2.push(elem.sc_teachers)
          });

          this.setState({ targetKeysTeachers: arr2 });
        }
      })
  }

  getTeachers() {
    fetch(`https://student-control.nti-center.ru/api/student-control/teachers`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let arr2 = [];
          response.data.map((user, index) => {
            arr2.push(
              {
                key: user.client_id,
                title: user.client_surname + " " + user.client_name + " " + user.client_patronymic
              }
            )
          });
          this.setState({ teachers: arr2 })
        }
      })
  }

  getTransfer_order(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/group/` + id + `/transfer_order/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache'
    })
      .then(async response => {
        if (!response.ok) {
          // Если сервер вернул ошибку (например, 404 или 500)
          const errorData = await response.json();
          throw new Error(errorData.error || 'Ошибка при загрузке файла');
        }

        // Получаем файл как Blob
        const blob = await response.blob();

        // Извлекаем имя файла из заголовка Content-Disposition (если есть)
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'transfer_order.docx'; // Значение по умолчанию
        if (contentDisposition && contentDisposition.includes('filename=')) {
          fileName = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, ''); // Удаляем кавычки, если они есть
        }

        // Создаем ссылку для скачивания
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Указываем имя файла

        // Добавляем ссылку в DOM и эмулируем клик
        document.body.appendChild(link);
        link.click();

        // Удаляем ссылку из DOM
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Ошибка:', error);
        openNotification('Ошибка', error.message, 1, 4.5); // Показываем уведомление об ошибке
      });
  }

  getOrder_successful(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/group/` + id + `/order_successful/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache'
    })
      .then(async response => {
        if (!response.ok) {
          // Если сервер вернул ошибку (например, 404 или 500)
          const errorData = await response.json();
          throw new Error(errorData.error || 'Ошибка при загрузке файла');
        }

        // Получаем файл как Blob
        const blob = await response.blob();

        // Извлекаем имя файла из заголовка Content-Disposition (если есть)
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'order_successful.docx'; // Значение по умолчанию
        if (contentDisposition && contentDisposition.includes('filename=')) {
          fileName = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, ''); // Удаляем кавычки, если они есть
        }

        // Создаем ссылку для скачивания
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Указываем имя файла

        // Добавляем ссылку в DOM и эмулируем клик
        document.body.appendChild(link);
        link.click();

        // Удаляем ссылку из DOM
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Ошибка:', error);
        openNotification('Ошибка', error.message, 1, 4.5); // Показываем уведомление об ошибке
      });
  }

  getOrder_unsuccessful(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/group/` + id + `/order_unsuccessful/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache'
    })
      .then(async response => {
        if (!response.ok) {
          // Если сервер вернул ошибку (например, 404 или 500)
          const errorData = await response.json();
          throw new Error(errorData.error || 'Ошибка при загрузке файла');
        }

        // Получаем файл как Blob
        const blob = await response.blob();

        // Извлекаем имя файла из заголовка Content-Disposition (если есть)
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'order_unsuccessful.docx'; // Значение по умолчанию
        if (contentDisposition && contentDisposition.includes('filename=')) {
          fileName = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, ''); // Удаляем кавычки, если они есть
        }

        // Создаем ссылку для скачивания
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Указываем имя файла

        // Добавляем ссылку в DOM и эмулируем клик
        document.body.appendChild(link);
        link.click();

        // Удаляем ссылку из DOM
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Ошибка:', error);
        openNotification('Ошибка', error.message, 1, 4.5); // Показываем уведомление об ошибке
      });
  }

  saveTeachers() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/teachers`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        teachers: this.state.targetKeysTeachers,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
        }
      })
  }


  getGroups(cId) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${cId}/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let arr = [];
          response.data.map((group, index) => {
            arr.push({
              scg_name: group.scg_status == 1 ?
                <div>
                  <Tooltip title="Активная группа"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> {group.scg_name}
                </div>
                :
                <div>
                  <Tooltip title="Не активная группа"><CloseCircleTwoTone twoToneColor="#eb2f96" /></Tooltip> {group.scg_name}
                </div>,
              scg_date_start: group.scg_date_start,
              scg_date_end: group.scg_date_end,
              scg_autostart: group.scg_autostart ? <Tooltip title="Автостарт включен"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> : <Tooltip title="Автостарт выключен"><CloseCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>,
              scg_autostop: group.scg_autostop ? <Tooltip title="Автостоп включен"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> : <Tooltip title="Автостоп выключен"><CloseCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>,
              events:
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                  <Tooltip title="Список слушателей">
                    <TeamOutlined onClick={() => { this.getGroupUsers(group.scg_id); this.setState({ groupId: group.scg_id, userListModal: true }); this.getAllListeners(); }} />
                  </Tooltip>
                  <Tooltip title="Объявления">
                    <SoundOutlined onClick={() => { this.getAds(group.scg_id); }} />
                  </Tooltip>
                  <Tooltip title="Настройки">
                    <SettingOutlined onClick={() => { this.setState({ groupSettingModal: true, groupSettingId: group.scg_id, groupSettingName: group.scg_name, groupSetting_date_start: group.scg_date_start, groupSetting_date_end: group.scg_date_end, groupSetting_autostart: group.scg_autostart, groupSetting_autostop: group.scg_autostop, groupSetting_status: group.scg_status }) }} />
                  </Tooltip>
                  <Tooltip title="Цифровой след">
                    <DesktopOutlined onClick={() => { this.getFoorPrint(group.scg_id); }} />
                  </Tooltip>
                  <Tooltip title="Сформировать приказ о зачислении">
                    <FileTextOutlined onClick={() => { this.getTransfer_order(group.scg_id); }} />
                  </Tooltip>
                  <Tooltip title="Сформировать приказ об отчислении успешных">
                    <FileDoneOutlined onClick={() => { this.getOrder_successful(group.scg_id); }} />
                  </Tooltip>
                  <Tooltip title="Сформировать приказ об отчислении не успешных">
                    <FileExcelOutlined onClick={() => { this.getOrder_unsuccessful(group.scg_id); }} />
                  </Tooltip>
                </div>
            });
          })
          this.setState({ groups: arr });
        }
      })
  }

  getFoorPrint(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups/${id}/footprint`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let resultsByUser = {};
          let resultsByUserDates = {};
          response.usersAndResults.forEach(result => {
            if (result.task_status == null) {

            } else {
              let { user_id, lesson_name, client_name, client_surname, client_patronymic, scr_date } = result;

              if (!resultsByUser[client_surname + " " + client_name + " " + client_patronymic + " id:" + user_id]) {
                resultsByUser[client_surname + " " + client_name + " " + client_patronymic + " id:" + user_id] = new Set(); // Используем Set для уникальности уроков
                resultsByUserDates[client_surname + " " + client_name + " " + client_patronymic + " id:" + user_id] = {};
              }
              resultsByUser[client_surname + " " + client_name + " " + client_patronymic + " id:" + user_id].add(lesson_name.trim());
              resultsByUserDates[client_surname + " " + client_name + " " + client_patronymic + " id:" + user_id][lesson_name.trim()] = moment(scr_date).format('DD-MM-YYYY HH:mm');
            }

          });

          this.setState({ footPaintModal: true, resLessons: response.lessons, footUserIds: Object.keys(resultsByUser), resultsByUser: resultsByUser, resultsByUserDates: resultsByUserDates });
        }
      })
  }

  getAds(id) {
    this.setState({ groupId: id });
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups/${id}/ads`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ ads_list: response.data, ads_modal: true });
        }
      })
  }

  getGroupUsers(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups/${id}/users`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let arr = [];
          response.data.map((elem) => {
            arr.push(elem.user_id)
          });

          this.setState({ targetKeys: arr });
        }
      })
  }

  addNewAds() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups/${this.state.groupId}/ads`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        name: this.state.ads_name,
        content: convertToRaw(this.state.editorStateAdsContent.getCurrentContent())
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.getAds(this.state.groupId);
        }
      })
  }

  deleteAds(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups/${this.state.groupId}/ads`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        scga_id: id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.getAds(this.state.groupId);
          }
        }
      })
  }

  getContentTabs = (key, event) => {
    if (key == 1) {
    }
    if (key == 2) {

    }
    if (key == 3) {

    }
    if (key == 4) {

    }
  }


  saveGroup() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        name: this.state.groupName,
        dateStart: this.state.groupStartDate,
        dateStop: this.state.groupStopDate,
        autostart: this.state.groupAutoStart,
        autostop: this.state.groupAutoStop,
        status: this.state.groupStatus,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({ createGroupModal: false });
          this.getGroups(this.state.courseId);
        }
      })
  }


  getAllListeners() {
    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          let arr2 = [];
          response.data.map((user, index) => {
            arr2.push(
              {
                key: user.client_id,
                title: user.client_surname + " " + user.client_name + " " + user.client_patronymic
              }
            )
          });
          this.setState({ userList: arr2 })
        }
      })
  }

  saveListeners() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/group/` + this.state.groupId + `/listeners`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        listeners: this.state.targetKeys,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
        }
      })
  }

  changeGroupSettings() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/` + this.state.courseId + `/group/` + this.state.groupSettingId + `/settings`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        groupSettingName: this.state.groupSettingName,
        groupSetting_date_start: this.state.groupSetting_date_start,
        groupSetting_date_end: this.state.groupSetting_date_end,
        groupSetting_autostart: this.state.groupSetting_autostart,
        groupSetting_autostop: this.state.groupSetting_autostop,
        groupSetting_status: this.state.groupSetting_status
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({
            groupSettingId: "",
            groupSettingModal: false,
            groupSettingName: "",
            groupSetting_date_start: "",
            groupSetting_date_end: "",
            groupSetting_autostart: 0,
            groupSetting_autostop: 0,
            groupSetting_status: 0
          });
          this.getCourses();
          this.getGroups(this.state.courseId);
        }
      })
  }

  createCourse() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        name: this.state.courseName
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.setState({ newCourseModal: false });
            this.getCourses();
          }
        }
      })
  }

  saveAdditionalMaterial() {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/module/${this.state.moduleId}/lesson/${this.state.lessonId}/additionalMaterials`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        name: this.state.additionalMaterialName,
        type: this.state.additionalMaterialType,
        content: this.state.additionalMaterialType == 'Файл' ? this.state.additionalMaterialLink : convertToRaw(this.state.editorStateAM.getCurrentContent()),
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.data) {
            openNotification('Успех', response.data, 3, 4.5);
            this.setState({ newCourseModal: false });
            this.getCourses();
            this.getAdditionalMaterials(this.state.lessonId);
          }
        }
      })
  }

  getAdditionalMaterials(id) {
    fetch(`https://student-control.nti-center.ru/api/student-control/course/${this.state.courseId}/module/${this.state.moduleId}/lesson/${id}/additionalMaterials`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ additionalMaterials: response.data })
        }
      })
  }


  render() {

    const addLink = (url) => {
      // Укажите максимальную длину
      const maxLength = 200; // например, 200 символов

      if (url.length > maxLength) {
        return; // Отмена добавления ссылки
      }

      // Здесь добавьте вашу логику для добавления ссылки
      // Например, используя `RichUtils` из draft-js для добавления ссылки
      // ... (ваша логика обработки ввода ссылки)

    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Проект',
        dataIndex: 'project_name',
        key: 'project_name',
      },
      {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    const group_columns = [
      {
        title: 'Название',
        dataIndex: 'scg_name',
        key: 'scg_name',
      },
      {
        title: 'Время начала',
        dataIndex: 'scg_date_start',
        key: 'scg_date_start',
      },
      {
        title: 'Время окончания',
        dataIndex: 'scg_date_end',
        key: 'scg_date_end',
      },
      {
        title: 'Автостарт',
        dataIndex: 'scg_autostart',
        key: 'scg_autostart',
      },
      {
        title: 'Автостоп',
        dataIndex: 'scg_autostop',
        key: 'scg_autostop',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];



    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Список курсов Student-Control</div>
          <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ edit: false, newCourseModal: true }) }}>Добавить курс</Button>
          <Modal title="Введите название курса" open={this.state.newCourseModal} onOk={() => { this.createCourse(); }} cancelText="Отменить" okText="Создать курс" onCancel={() => { this.setState({ newCourseModal: false }) }}>
            <Input name="courseName" defaultValue={this.state.courseName} onChange={(e) => { this.ChangeInputValue(e); }} />
          </Modal>
          <Table dataSource={this.state.courses} columns={columns} />
          <Drawer
            title={this.state.edit === true ? "Редактирование курса" : "Добавление курса"}
            placement="bottom"
            getContainer={false}
            height={"100vh"}
            onClose={() => { this.setState({ modal: false }) }}
            open={this.state.modal}
            extra={<div></div>}
          >
            <Tabs defaultActiveKey="1" onTabClick={this.getContentTabs} items={[
              {
                key: '1',
                label: 'О курсе',
                children: <div>
                  <h2>О курсе</h2>
                  <div className="modal_field_name">Название курса</div>
                  <Input name="courseName" onChange={this.ChangeInputValue.bind(this)} value={this.state.courseName} placeholder="Введите название" />
                  <div className="modal_field_name">Описание курса</div>
                  <div className="custom_editor">

                    <Editor className="editorStateS" wrapperClassName="demo-wrapper" editorClassName="demo-editor" toolbarClassName="demo-toolbar" editorState={this.state.editorStateS} onEditorStateChange={this.onChangeEditor} handleKeyCommand={this.handleKeyCommand} />

                  </div>

                  <div style={{ display: 'flex', alignItems: 'start', gap: 20 }}>
                    <div>
                      <div className="modal_field_name">Проект</div>
                      <Input name="courseProject" onChange={this.ChangeInputValue.bind(this)} value={this.state.courseProject} placeholder="Введите название" />
                    </div>
                    <div>
                      <div className="modal_field_name">Год обучения</div>
                      <Input name="courseYear" onChange={this.ChangeInputValue.bind(this)} value={this.state.courseYear} placeholder="Введите название" />
                    </div>
                  </div>

                  <Button style={{ marginTop: 20 }} type="primary" onClick={() => { this.saveAboutCourse(); }}>Сохранить данные о курсе</Button>
                </div>,
              },
              {
                key: '2',
                label: 'Программа курса',
                children: <div>
                  {
                    this.state.modules.length == 0 ?
                      "" :
                      <div>
                        <h2>Модули</h2>
                        <div className="lessons">
                          {this.state.modules.map((module, index) => {
                            return (
                              <div key={index} className="lesson_item">
                                <div>
                                  <EyeOutlined onClick={() => { this.getLessonsOnModule(module.scm_id); this.setState({ moduleName: module.scm_name, moduleId: module.scm_id }); }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                                  <Popconfirm
                                    title="Вы уверены что хотите удалить этот модуль?"
                                    okText="Да"
                                    onConfirm={() => { this.deleteModule(module.scm_id); }}
                                    cancelText="Нет"
                                  >
                                    <DeleteOutlined />
                                  </Popconfirm>
                                </div>
                                <div className="name">{module.scm_turn}. {module.scm_name}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                  }
                  <Button onClick={() => { this.setState({ newModule: true }) }} style={{ marginTop: 20 }}>Добавить модуль</Button>

                  <Modal open={this.state.newModule} title={"Добавление модуля"} onOk={() => { this.addNewModule() }} okText={"Сохранить"} onCancel={() => { this.setState({ newModule: false }) }}>
                    <div className="modal_field_name">Название модуля</div>
                    <Input name="newModuleName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newModuleName} placeholder="Введите название" />
                  </Modal>

                  <Modal open={this.state.newLessonModal} title={"Добавление элемента"} onOk={() => { this.addNewLesson() }} okText={"Добавить"} onCancel={() => { this.setState({ newLessonModal: false }) }}>
                    <div className="modal_field_name">Тип элемента</div>
                    <Radio.Group options={['Урок', 'Тест', 'Текстовое задание']} onChange={(e) => { this.setState({ newLessonType: e.target.value }) }} value={this.state.newLessonType} optionType="button" />
                    <div className="modal_field_name">Название урока</div>
                    <Input name="newLessonName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newLessonName} placeholder="Введите название" />
                  </Modal>

                  <Modal open={this.state.lessonsModal} width={1000} title={"Элементы модуля " + this.state.moduleName} onOk={() => { }} okText={"Сохранить"} onCancel={() => { this.setState({ lessonsModal: false }) }}>
                    {
                      this.state.lessons.length == 0 ?
                        <Button onClick={() => { this.setState({ newLessonModal: true, newLessonName: "" }) }} style={{ marginTop: 20 }}>Добавить урок</Button>
                        :
                        <div>
                          <div className="lessons">{
                            this.state.lessons.map((lesson, index) => {
                              return (
                                <div key={index} className="lesson_item">
                                  <div>
                                    <EyeOutlined onClick={() => { this.getLesson(lesson.scl_id); this.getHomeWork(lesson.scl_id); this.getAdditionalMaterials(lesson.scl_id); }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                                    <Popconfirm
                                      title="Вы уверены что хотите удалить этот элемент?"
                                      okText="Да"
                                      onConfirm={() => { this.deleteLesson(lesson.scl_id); }}
                                      cancelText="Нет"
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  </div>
                                  <div className="name">{lesson.scl_name.trim()}</div>
                                </div>
                              )
                            })
                          }
                          </div>
                          <Button onClick={() => { this.setState({ newLessonModal: true, newLessonName: "" }) }} style={{ marginTop: 20 }}>Добавить урок</Button>
                        </div>
                    }

                  </Modal>


                </div>,
              },
              {
                key: '3',
                label: 'Преподаватели',
                children: <div>
                  <h2>Преподаватели</h2>
                  <div className="teachers_place">
                    <Transfer
                      dataSource={this.state.teachers}
                      titles={['Все преподаватели', 'Преподаватели курса']}
                      targetKeys={this.state.targetKeysTeachers}
                      onChange={this.onChangeTransferTeachers}
                      showSearch
                      listStyle={{
                        width: "100%",
                        height: 300,
                      }}
                      render={(item) => item.title}
                    />
                    <Button type="primary" style={{ marginTop: 20 }} onClick={() => { this.saveTeachers(); }}>Сохранить преподавателей</Button>
                  </div>
                </div>,
              },
              {
                key: '4',
                label: 'Группы',
                children: <div>
                  <div className="flex" style={{ justifyContent: "space-between" }}>
                    <h2>Группы</h2>
                    <Button onClick={() => { this.setState({ createGroupModal: true }) }}>Создать группу</Button>
                  </div>
                  <Table dataSource={this.state.groups} columns={group_columns} />
                  <Modal title="Добавление группы" open={this.state.createGroupModal} onOk={() => { this.saveGroup(); }} okText={"Сохранить"} onCancel={() => { this.setState({ createGroupModal: false }) }}>
                    <div className="modal_field_name">Название группы</div>
                    <Input name="groupName" onChange={this.ChangeInputValue.bind(this)} value={this.state.groupName} placeholder="Введите название" />
                    <div className="modal_field_name">Даты старта и окончания</div>
                    <ConfigProvider locale={locale}>
                      <DatePicker.RangePicker allowClear={false} format={'DD.MM.YYYY'} onChange={(date, dateString) => { this.setState({ groupStartDate: dateString[0], groupStopDate: dateString[1] }) }} placeholder={['Выберите дату начала', 'Выберите дату окончания']} style={{ width: '100%' }} />
                    </ConfigProvider>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Автоматический запуск группы</div>
                      <Switch defaultChecked={this.state.groupAutoStart} onChange={(checked) => { this.setState({ groupAutoStart: checked }) }} />
                    </div>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Автоматическое завершение группы</div>
                      <Switch defaultChecked={this.state.groupAutoStop} onChange={(checked) => { this.setState({ groupAutoStop: checked }) }} />
                    </div>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Выкл/Вкл</div>
                      <Switch defaultChecked={this.state.groupStatus} onChange={(checked) => { this.setState({ groupStatus: checked }) }} />
                    </div>
                  </Modal>

                  <Modal width={'100vw'} height={'100vh'} title="Список слушателей" open={this.state.userListModal} onOk={() => { this.saveListeners() }} okText={"Сохранить"} onCancel={() => { this.setState({ userListModal: false }) }}>
                    <Transfer
                      dataSource={this.state.userList}
                      titles={['Все слушатели', 'Слушатели в этой группе']}
                      targetKeys={this.state.targetKeys}
                      onChange={this.onChangeTransfer}
                      showSearch
                      listStyle={{
                        width: "50%",
                        height: 300,
                      }}
                      render={(item) => item.title}
                    />
                  </Modal>

                  <Modal className="digitalFootModal" title="Цифровой след" width={'100vw'} height={'100vh'} open={this.state.footPaintModal} onOk={() => { this.setState({ footPaintModal: false }) }} okText={"Сохранить"} onCancel={() => { this.setState({ footPaintModal: false }) }}>
                    <div>
                      <h2>Таблица завершенных заданий пользователей</h2>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>User ID</th>
                            {
                              this.state.resLessons.length == 0 ?
                                ""
                                :
                                this.state.resLessons.map((lesson) => {
                                  return (<th key={lesson.scl_id}>{lesson.scl_name.trim()}</th>)
                                }
                                )}
                            <td>Результат</td>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                          {
                            this.state.footUserIds.length == 0 ?
                              ""
                              :
                              this.state.footUserIds.map((userId) => {
                                let userProgress = 0;
                                return (<tr key={userId}>
                                  <td>{userId}</td>
                                  {
                                    this.state.resLessons.length == 0 ?
                                      ""
                                      :
                                      this.state.resLessons.map((lesson, index) => {
                                        if (this.state.resultsByUser[userId].has(lesson.scl_name.trim())) {
                                          userProgress++;
                                        }

                                        let lessName = lesson.scl_name.trim();
                                        return (
                                          <td key={lesson.scl_id}>
                                            {this.state.resultsByUser[userId].has(lesson.scl_name.trim()) ? <Tooltip title={this.state.resultsByUserDates[userId][lessName]}>✅</Tooltip> : '❌'}
                                          </td>
                                        )
                                      })}
                                  <td>{Math.round((userProgress / this.state.resLessons.length * 100) * 100) / 100}%</td>
                                </tr>
                                )
                              })}
                        </tbody>
                      </table>
                    </div>
                  </Modal>

                  <Modal title="Настройки группы" open={this.state.groupSettingModal} onOk={() => { this.changeGroupSettings(); }} okText={"Сохранить"} onCancel={() => { this.setState({ groupSettingModal: false }) }}>
                    <div className="modal_field_name">Название группы</div>
                    <Input name="groupSettingName" onChange={this.ChangeInputValue.bind(this)} value={this.state.groupSettingName} placeholder="Введите название" />
                    <div className="modal_field_name">Даты старта и окончания</div>
                    <ConfigProvider locale={locale}>
                      <DatePicker.RangePicker allowClear={false} value={[dayjs(this.state.groupSetting_date_start, 'DD.MM.YYYY'), dayjs(this.state.groupSetting_date_end, 'DD.MM.YYYY')]} format={'DD.MM.YYYY'} onChange={(date, dateString) => { console.log(dateString); this.setState({ groupSetting_date_start: dateString[0], groupSetting_date_end: dateString[1] }) }} placeholder={['Выберите дату начала', 'Выберите дату окончания']} style={{ width: '100%' }} />
                    </ConfigProvider>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Автоматический запуск группы</div>
                      <Switch defaultChecked={this.state.groupSetting_autostart} onChange={(checked) => { this.setState({ groupSetting_autostart: checked }) }} />
                    </div>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Автоматическое завершение группы</div>
                      <Switch defaultChecked={this.state.groupSetting_autostop} onChange={(checked) => { this.setState({ groupSetting_autostop: checked }) }} />
                    </div>
                    <div className="flex" style={{ alignItems: "center", marginTop: 15, gap: 10 }}>
                      <div style={{ marginBottom: 0, marginTop: 0 }} className="modal_field_name">Выкл/Вкл</div>
                      <Switch defaultChecked={this.state.groupSetting_status} onChange={(checked) => { this.setState({ groupSetting_status: checked }) }} />
                    </div>
                  </Modal>
                </div>
              },
            ]
            }
            />
          </Drawer>

          {
            this.state.ads_modal == true ?
              <div className="customModal">
                <div className="customModal__body">
                  <div className="customModal__header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Объявления группы <Button type="primary" onClick={() => { this.setState({ addAds_modal: true }) }}>Добавить</Button></div>
                    <Button onClick={() => { this.setState({ ads_modal: false }) }}>Закрыть</Button>
                  </div>
                  {
                    this.state.ads_list.length == 0 ?
                      "Нет объявлений для этой группы"
                      :
                      <div className="homeWorks_grid"> {
                        this.state.ads_list.map((ads, index) => {
                          return (
                            <Card size="small" key={"ads_" + index} className="ads_item" title={ads.scga_name} extra={<Popconfirm
                              title="Вы уверены что хотите удалить объявление?"
                              okText="Да"
                              onConfirm={() => { this.deleteAds(ads.scga_id); }}
                              cancelText="Нет"
                            ><Tooltip title="Удалить"><DeleteOutlined /></Tooltip></Popconfirm>}>
                              <div>{JSON.parse(ads.scga_content).blocks[0].text.substring(0, 30) + "..."}</div>
                            </Card>
                          )
                        })
                      }
                      </div>
                  }

                  {
                    this.state.addAds_modal == true ?
                      <div className="addHW">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button type="primary" onClick={() => { this.addNewAds(); }}>Сохранить</Button>
                          <Button onClick={() => { this.setState({ addAds_modal: false }) }}>Закрыть</Button>
                        </div>

                        <div>
                          <div className="modal_field_name">Заголовок</div>
                          <Input value={this.state.ads_name} name="ads_name" onChange={this.ChangeInputValue.bind(this)} />
                          <div className="modal_field_name">Контент</div>
                          <Editor className="editorStateAdsContent" wrapperClassName="demo-wrapper-AdsContent" editorClassName="demo-editor-AdsContent" toolbarClassName="demo-toolbar-AdsContent" editorState={this.state.editorStateAdsContent} onEditorStateChange={this.onChangeEditorAdsContent} handleKeyCommand={this.handleKeyCommandAdsContent} />
                        </div>
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
              :
              ""
          }

          {
            this.state.editLesson == true ?
              <div className="customModal">
                <div className="customModal__body">
                  <div className="customModal__header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button type="primary" onClick={() => { this.saveLesson(); }}>Сохранить урок</Button>
                      <Button onClick={() => { this.setState({ editLesson: false }) }}>Закрыть</Button>
                    </div>
                  </div>
                  {
                    this.state.lessonType == "Тест" ?
                      <div>
                        <div className="modal_field_name">Название теста</div>
                        <Input name="lessonName" onChange={this.ChangeInputValue.bind(this)} value={this.state.lessonName} placeholder="Введите название" />
                        <h3 className="modal_field_name">Вопросы теста</h3>
                        {
                          this.state.lessonContent.length == 0 ?
                            "" :
                            <div>
                              {
                                this.state.lessonContent.map((question, index) => {
                                  return (
                                    <div key={index} className="homeWork_item">
                                      Пункт {index + 1}
                                      <Tooltip title="Удалить пункт">
                                        <MinusCircleOutlined
                                          onClick={() => {
                                            let ques = this.state.lessonContent;
                                            // delete ques[index];
                                            ques.splice(index, index);
                                            this.setState({ lessonContent: ques });
                                          }}
                                        />
                                      </Tooltip>
                                      <Input addonBefore={"Вопрос:"} defaultValue={question.title} onChange={(e) => { question.title = e.target.value }} />
                                      {
                                        question.variants.map((ans, index2) => {
                                          return (
                                            <Input key={index2} addonBefore={
                                              <div>
                                                <Tooltip title="Удалить ответ">
                                                  <MinusCircleOutlined onClick={() => {
                                                    let ques = this.state.lessonContent;
                                                    delete ques[index].variants[index2];
                                                    this.setState({ lessonContent: ques });
                                                  }}
                                                  />
                                                </Tooltip>
                                                Ответ:</div>
                                            }
                                              defaultValue={ans.title}
                                              onChange={(e) => { ans.title = e.target.value }}
                                              addonAfter={<div style={{ cursor: 'pointer' }} onClick={() => {
                                                let ques = this.state.lessonContent;
                                                ans.flag == true ? delete ques[index].variants[index2].flag : ques[index].variants[index2].flag = true
                                                this.setState({ lessonContent: ques });
                                              }}>{ans.flag && ans.flag == true ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleTwoTone twoToneColor="#eb2f96" />}</div>} />
                                          )
                                        })
                                      }
                                      <Tooltip title="Добавить ответ">
                                        <PlusCircleOutlined
                                          onClick={() => {
                                            let ques = this.state.lessonContent;
                                            ques[index].variants.push({ title: "" });
                                            this.setState({ lessonContent: ques });
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                  )
                                })
                              }

                            </div>
                        }


                        <Button onClick={() => {
                          let ques = this.state.lessonContent;
                          ques.push({
                            "title": "",
                            "code": "",
                            "variants": []
                          });
                          this.setState({ lessonContent: ques });
                        }}>Добавить пункт</Button>
                        <h3 className="modal_field_name">Проходной балл</h3>
                        <Input type="number" style={{ width: 100 }} onChange={this.ChangeInputValue.bind(this)} name="lessonContentPassingScore" defaultValue={this.state.lessonContentPassingScore} />
                        <br />
                        <Checkbox disabled checked={this.state.lessonNeedAnswer} style={{ marginTop: 20 }} onChange={(e) => { this.setState({ lessonNeedAnswer: e.target.checked }); }}>Слушатель должен дать ответ</Checkbox>
                      </div>
                      :
                      ""
                  }

                  {
                    this.state.lessonType == "Текстовое задание" ?
                      <div>
                        <div className="modal_field_name">Название</div>
                        <Input name="lessonName" onChange={this.ChangeInputValue.bind(this)} value={this.state.lessonName} placeholder="Введите название" />
                        <div className="modal_field_name">Контент</div>
                        <Editor className="editorStateLesson" wrapperClassName="demo-wrapper-hw" editorClassName="demo-editor-hw" toolbarClassName="demo-toolbar-hw" editorState={this.state.editorStateLesson} onEditorStateChange={this.onChangeEditorLesson} handleKeyCommand={this.handleKeyCommandLesson} />
                        <Checkbox checked={this.state.lessonNeedAnswer} style={{ marginTop: 20 }} onChange={(e) => { this.setState({ lessonNeedAnswer: e.target.checked }); }}>Слушатель должен дать ответ</Checkbox>
                      </div>
                      :
                      ""
                  }

                  {
                    this.state.lessonType == "Урок" ?
                      <Tabs defaultActiveKey="1" items={[
                        {
                          key: '1',
                          label: 'Об Уроке',
                          children: <div>

                            <div className="modal_field_name">Название урока</div>
                            <Input name="lessonName" onChange={this.ChangeInputValue.bind(this)} value={this.state.lessonName} placeholder="Введите название" />
                            <div className="modal_field_name">Контент урока</div>

                            <Editor className="editorStateLesson" wrapperClassName="demo-wrapper-lesson" editorClassName="demo-editor-lesson" toolbarClassName="demo-toolbar-lesson" editorState={this.state.editorStateLesson} onEditorStateChange={this.onChangeEditorLesson} handleKeyCommand={this.handleKeyCommandLesson} />

                          </div>,
                        },
                        {
                          key: '2',
                          label: 'Видеоматериалы',
                          children: <div>
                            <div className="modal_field_name">Видео урока - вставьте код уроков напрямую в поле</div>
                            <TextArea rows={4} name="lessonVideos" onChange={this.ChangeInputValue.bind(this)} value={this.state.lessonVideos} />
                          </div>
                        },
                        // {
                        //   key: '3',
                        //   label: 'Задания',
                        //   children: <div>
                        //     <div className="modal_field_name">Задания</div>
                        //     {
                        //       this.state.homeWorks.length == 0 ?
                        //         "" :
                        //         <div className="homeWorks_grid">
                        //           {this.state.homeWorks.map((hw, index) => {

                        //             return (
                        //               <div key={index} className="homeWork">
                        //                 <Card title={<Tooltip title={hw.sch_name}>{hw.sch_name}</Tooltip>} extra={<EyeOutlined onClick={() => { this.setState({ homeWorkModal: true, homeWorkName: hw.sch_name, homeWorkPassingScore: hw.sch_passing_score, homeWorkNeedAnswer: hw.sch_need_answer == 1 ? true : false, homeWorkId: hw.id, homeWorkQuestions: JSON.parse(hw.sch_questions), editorStateHW: hw.sch_type == "Тест" ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(JSON.parse(hw.sch_questions))), homeWorkType: hw.sch_type }) }} />}>
                        //                   Тип: {hw.sch_type}
                        //                 </Card>
                        //               </div>
                        //             )
                        //           })}
                        //         </div>
                        //     }
                        //     <Button onClick={() => { this.setState({ homeWorkModal: true, homeWorkName: '', homeWorkId: "new", homeWorkQuestions: [], homeWorkType: "Письменный ответ" }) }}>Добавить задание</Button>

                        //     {
                        //       this.state.homeWorkModal == true ?
                        //         <div className="addHW">
                        //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        //             <Button type="primary" onClick={() => { this.sendAnswers(); }}>Сохранить задание</Button>
                        //             <Button onClick={() => { this.setState({ homeWorkModal: false }) }}>Закрыть</Button>
                        //           </div>
                        //           <div>
                        //             {
                        //               this.state.homeWorkId == "new" ?
                        //                 <div>
                        //                   <h3 className="modal_field_name">Тип задания</h3>
                        //                   <Radio.Group options={['Письменный ответ', 'Тест']} onChange={(e) => { this.setState({ homeWorkType: e.target.value, homeWorkPassingScore: 0 }) }} value={this.state.homeWorkType} optionType="button" />
                        //                 </div>
                        //                 :
                        //                 <div><h3 className="modal_field_name">Тип задания - {this.state.homeWorkType}</h3></div>

                        //             }

                        //             <h3 className="modal_field_name">Название задания</h3>
                        //             <Input name="homeWorkName" onChange={this.ChangeInputValue.bind(this)} value={this.state.homeWorkName} placeholder="Введите название" />
                        //             {
                        //               this.state.homeWorkType == "Тест" ?
                        //                 <div>
                        //                   <h3 className="modal_field_name">Вопросы теста</h3>
                        //                   {
                        //                     this.state.homeWorkQuestions.length == 0 ?
                        //                       "" :
                        //                       <div>
                        //                         {
                        //                           this.state.homeWorkQuestions.map((question, index) => {
                        //                             return (
                        //                               <div key={index} className="homeWork_item">
                        //                                 Пункт {index + 1}
                        //                                 <Tooltip title="Удалить пункт">
                        //                                   <MinusCircleOutlined
                        //                                     onClick={() => {
                        //                                       let ques = this.state.homeWorkQuestions;
                        //                                       // delete ques[index];
                        //                                       ques.splice(index, index);
                        //                                       this.setState({ homeWorkQuestions: ques });
                        //                                     }}
                        //                                   />
                        //                                 </Tooltip>
                        //                                 <Input addonBefore={"Вопрос:"} defaultValue={question.title} onChange={(e) => { question.title = e.target.value }} />
                        //                                 {
                        //                                   question.variants.map((ans, index2) => {
                        //                                     return (
                        //                                       <Input key={index2} addonBefore={
                        //                                         <div>
                        //                                           <Tooltip title="Удалить ответ">
                        //                                             <MinusCircleOutlined onClick={() => {
                        //                                               let ques = this.state.homeWorkQuestions;
                        //                                               delete ques[index].variants[index2];
                        //                                               this.setState({ homeWorkQuestions: ques });
                        //                                             }}
                        //                                             />
                        //                                           </Tooltip>
                        //                                           Ответ:</div>
                        //                                       }
                        //                                         defaultValue={ans.title}
                        //                                         onChange={(e) => { ans.title = e.target.value }}
                        //                                         addonAfter={<div style={{ cursor: 'pointer' }} onClick={() => {
                        //                                           let ques = this.state.homeWorkQuestions;
                        //                                           ans.flag == true ? delete ques[index].variants[index2].flag : ques[index].variants[index2].flag = true
                        //                                           this.setState({ homeWorkQuestions: ques });
                        //                                         }}>{ans.flag && ans.flag == true ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleTwoTone twoToneColor="#eb2f96" />}</div>} />
                        //                                     )
                        //                                   })
                        //                                 }
                        //                                 <Tooltip title="Добавить ответ">
                        //                                   <PlusCircleOutlined
                        //                                     onClick={() => {
                        //                                       let ques = this.state.homeWorkQuestions;
                        //                                       ques[index].variants.push({ title: "" });
                        //                                       this.setState({ homeWorkQuestions: ques });
                        //                                     }}
                        //                                   />
                        //                                 </Tooltip>
                        //                               </div>
                        //                             )
                        //                           })
                        //                         }

                        //                       </div>
                        //                   }


                        //                   <Button onClick={() => {
                        //                     let ques = this.state.homeWorkQuestions;
                        //                     ques.push({
                        //                       "title": "",
                        //                       "code": "",
                        //                       "variants": []
                        //                     });
                        //                     this.setState({ homeWorkQuestions: ques });
                        //                   }}>Добавить пункт</Button>
                        //                   <h3 className="modal_field_name">Проходной балл</h3>
                        //                   <Input type="number" style={{ width: 100 }} onChange={this.ChangeInputValue.bind(this)} name="homeWorkPassingScore" defaultValue={this.state.homeWorkPassingScore} />

                        //                 </div>
                        //                 :
                        //                 ""
                        //             }

                        //             {
                        //               this.state.homeWorkType == "Письменный ответ" ?
                        //                 <div>
                        //                   <Editor className="editorStateHW" wrapperClassName="demo-wrapper-hw" editorClassName="demo-editor-hw" toolbarClassName="demo-toolbar-hw" editorState={this.state.editorStateHW} onEditorStateChange={this.onChangeEditorHW} handleKeyCommand={this.handleKeyCommandHW} />
                        //                   <Checkbox checked={this.state.homeWorkNeedAnswer} style={{ marginTop: 20 }} onChange={(e) => { this.setState({ homeWorkNeedAnswer: e.target.checked }); }}>Слушатель должен дать ответ</Checkbox>
                        //                 </div>
                        //                 :
                        //                 ""
                        //             }

                        //           </div>
                        //         </div>
                        //         :
                        //         ""
                        //     }

                        //   </div>
                        // },
                        // {
                        //   key: '4',
                        //   label: 'Дополнительные материалы',
                        //   children: <div>
                        //     <div className="modal_field_name">Дополнительные материалы</div>
                        //     {
                        //       this.state.additionalMaterials.length == 0 ?
                        //         "Нет дополнительных материалов"
                        //         :
                        //         <div className="homeWorks_grid">
                        //           {this.state.additionalMaterials.map((hw, index) => {

                        //             return (
                        //               <div key={index} className="homeWork">
                        //                 <Card title={<Tooltip title={hw.scam_name}>{hw.scam_name}</Tooltip>} extra={<Tooltip title="Удалить"><DeleteOutlined onClick={() => { }} /></Tooltip>}>
                        //                   Тип: {hw.scam_type}
                        //                 </Card>
                        //               </div>
                        //             )
                        //           })}
                        //         </div>
                        //     }

                        //     <Button onClick={() => { this.setState({ additionalMaterialModal: true }); }}>Добавить материал</Button>

                        //     {
                        //       this.state.additionalMaterialModal == true ?
                        //         <div className="addHW">
                        //           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        //             <Button type="primary" onClick={() => { this.saveAdditionalMaterial(); }}>Сохранить</Button>
                        //             <Button onClick={() => { this.setState({ additionalMaterialModal: false }) }}>Закрыть</Button>
                        //           </div>
                        //           <div className="modal_field_name">Тип:</div>
                        //           <Radio.Group options={['Файл', 'Текстовый материал']} onChange={(e) => { this.setState({ additionalMaterialType: e.target.value }) }} value={this.state.additionalMaterialType} optionType="button" />
                        //           <div className="modal_field_name">Название материала</div>
                        //           <Input name="additionalMaterialName" onChange={this.ChangeInputValue.bind(this)} value={this.state.additionalMaterialName} placeholder="Введите название" />
                        //           {
                        //             this.state.additionalMaterialType == "Файл" ?
                        //               <div>
                        //                 <div className="modal_field_name">Ссылка на материала</div>
                        //                 <Input name="additionalMaterialLink" onChange={this.ChangeInputValue.bind(this)} value={this.state.additionalMaterialLink} placeholder="Введите ссылку" />
                        //               </div>
                        //               :
                        //               <div>
                        //                 <div className="modal_field_name">Внесите контент</div>
                        //                 <Editor toolbar={{
                        //                   link: {
                        //                     // Ваша логика для обработки ссылки 
                        //                     // Убедитесь, что используете `addLink` при добавлении ссылки
                        //                     onAddLink: (url) => addLink(url),
                        //                   },
                        //                 }} className="editorStateAM" wrapperClassName="demo-wrapper-am" editorClassName="demo-editor-am" toolbarClassName="demo-toolbar-am" editorState={this.state.editorStateAM} onEditorStateChange={this.onChangeEditorAM} handleKeyCommand={this.handleKeyCommandAM} />
                        //               </div>
                        //           }

                        //         </div>
                        //         :
                        //         ""
                        //     }
                          // </div>
                        // }
                      ]}
                      />
                      :
                      ""
                  }

                </div>
              </div>
              :
              ""
          }
        </div>
      </div>
    )


  }

  componentDidMount() {
    this.getCourses();
  }



}

