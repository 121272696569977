import React, { useEffect, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const CurseMaterialRender = ({ content }) => {
  const rawContent = content;
  
  const contentState = convertFromRaw(rawContent);

  const options = {
    entityStyleFn: (entity) => {
      const entityType = entity.getType().toLowerCase();
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a', // Тег, который будет использоваться для ссылок
          attributes: {
            href: data.url, // Атрибут href
            target: '_blank', // Открывать ссылку в новой вкладке
          },
          style: {
            color: '#1155cc', // Цвет текста ссылки
            textDecoration: 'underline', // Подчеркивание ссылки
          },
        };
      }
      // Добавьте другие типы сущностей, если нужно
    },
  };

  let htmlContent = draftToHtml(rawContent, options);
  htmlContent = htmlContent.replace(/<a href=/g, '<a target="_blank" href=');
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default CurseMaterialRender;