import React, { Component } from 'react';
import menu_logo from '../images/menu_logo.png';
import { MenuFoldOutlined, CloseOutlined } from '@ant-design/icons'; 
import { Link } from "react-router-dom";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false, // Состояние меню: открыто/закрыто
    };
  }

  // Метод для переключения состояния меню
  toggleMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div className="mobile-menu">
        {/* Кнопка с иконкой */}
        <button className="menu-toggle" onClick={this.toggleMenu}>
          {isOpen ? (
              <CloseOutlined className="icon" />
            ) : (
              <MenuFoldOutlined className="icon" /> 
          )}
        </button>

        <img src={menu_logo}  className='header_Logo' />

        {/* Список пунктов меню с анимацией */}
        <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
          <li><Link to="/me">Профиль и курсы</Link></li>
          <li><Link to="/me/certificates">Сертификаты и дипломы</Link></li>
          <li><Link to="/">Выход</Link></li>
        </ul>
      </div>
    );
  }
}

export default MobileMenu;