import React from "react";
import { Tooltip, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Card, Avatar } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { openNotification } from "../../functions";
import { NavBar } from "../../components/navBar";
import { Link } from "react-router-dom";

const { Option } = Select;

export default class Certificates extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      certificates: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  getMyCertificates() {
    fetch(`https://student-control.nti-center.ru/api/me/certificates/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          this.setState({
            certificates: []
          });
        } else {
          this.setState({
            certificates: response.data
          });
        }
      })
  }

  downLoadCert(id) {
    fetch(`https://student-control.nti-center.ru/api/me/certificates/` + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
    .then((response) => {
      if (response.ok) {
        // Если ответ успешный, преобразуем его в Blob
        return response.blob();
      }
      throw new Error("Не удалось скачать файл.");
    })
    .then((blob) => {
      // Создаем ссылку для скачивания
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "example.pdf"); // Имя файла для скачивания
      document.body.appendChild(link);
      link.click();
      link.remove(); // Удаляем ссылку после скачивания
    })
    .catch((error) => {
      console.error("Ошибка:", error);
    });
  }

  render() {

    return (
      <div>
        <NavBar />
        <div className="pageContent">
          {this.state.certificates.length == 0 ?
            "Вы не получили еще ни одного сертификата"
            :
            <div className="courses">
              {
                this.state.certificates.map((cert, index) => {
                  return (
                    <Card className="certificate_cart">
                      Сертификат за курс: <b>{cert.sc_name}</b> <br />
                      Дата получения: {cert.c_date}<br />
                      <Button style={{ marginTop: 10 }} onClick={() => {this.downLoadCert(cert.с_id);}}>Скачать</Button>
                    </Card>
                  )
                })
              }
            </div>
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getMyCertificates();
  }

}