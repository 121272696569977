import './App.css';

import { ConfigProvider } from 'antd';

import mini_logo from './images/mini_logo.png';
import {
  RedditOutlined,
  BankOutlined,
  IssuesCloseOutlined,
  AppstoreAddOutlined,
  ProjectOutlined,
  PieChartOutlined,
  TeamOutlined,
  DownloadOutlined,
  GroupOutlined,
  HomeOutlined,
  FileOutlined,
  InsertRowLeftOutlined,
  UnorderedListOutlined,
  QrcodeOutlined,
  BookOutlined
} from '@ant-design/icons';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Newclients from './pages/admin/newclients';
import Login from './pages/Login';
import StudentControlCourses from './pages/admin/studentControlCourses';
import Users from './pages/admin/users';
import Mediafiles from './pages/admin/mediafiles';
import Me from './pages/client/me';
import MyCourses from './pages/client/mycourses';
import Course from './pages/client/course';
import Teacher from './pages/teacher/teacher';
import TeacherCourse from './pages/teacher/course';

import { jwtDecode } from 'jwt-decode';
import Commercecourse from './pages/client/commercecourse';
import Certificates from './pages/client/certificates';
import CommerceStat from './pages/admin/commerceStat';

const defaultData = {
  borderRadius: 16,
  colorPrimary: '#8C72F3',
  Button: {
    colorPrimary: '#8C72F3',
  },
  Input: {
    addonBg: '#8C72F3',
  }
};

function App() {

  const [data, setData] = React.useState(defaultData);

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: data.colorPrimary,
            borderRadius: data.borderRadius,
          },
          components: {
            Button: {
              colorPrimary: data.Button?.colorPrimary,
            },
            // Input: {
            //   addonBg: data.Input?.addonBg,
            // }
          },
        }}
      >
        <div className='wrapper'>
          <div className={window.location.pathname == '/' ? "content" : "content pad20"}>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/users' element={<RequireAuth><Users role='1' /></RequireAuth>} />
              <Route path='/clients' element={<RequireAuth><Newclients role='1' /></RequireAuth>} />
              <Route path='/student-control-courses' element={<RequireAuth><StudentControlCourses role='1' /></RequireAuth>} />
              <Route path='/mediafiles' element={<RequireAuth><Mediafiles role='1' /></RequireAuth>} />
              <Route path='/commerce-stat' element={<RequireAuth><CommerceStat role='1' /></RequireAuth>} />
              <Route path='/me' element={<RequireAuth><Me role='2' /></RequireAuth>} />
              <Route path='/me/certificates' element={<RequireAuth><Certificates role='2' /></RequireAuth>} />
              <Route path='/me/courses' element={<RequireAuth><MyCourses role='2' /></RequireAuth>} />
              <Route path="/me/courses/:id"  element={<RequireAuth><Course role='2' /></RequireAuth>} />
              <Route path="/me/commerce/:id"  element={<RequireAuth><Commercecourse role='2' /></RequireAuth>} />
              <Route path="/teacher"  element={<RequireAuth><Teacher role='3' /></RequireAuth>} />
              <Route path="/teacher/course/:id"  element={<RequireAuth><TeacherCourse role='3' /></RequireAuth>} />
            </Routes>
          </div>
        </div>
      </ConfigProvider>
    </Router>
  );
}

export default App;

function RequireAuth({ children }) {
  let location = useLocation();
  if (!localStorage.getItem('token') || jwtDecode(localStorage.getItem("token")).role != Number(children.props.role)) {
    localStorage.clear();
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}