import React from "react";
import { Tooltip, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Card, Avatar } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { openNotification } from "../../functions";
import { NavBar } from "../../components/navBar";
import { Link } from "react-router-dom";

const { Option } = Select;

export default class Me extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      me: "",
      ClientId: "",
      ClientName: "",
      ClientSurname: "",
      ClientPatronymic: "",
      ClientMail: "",
      ClientWorkMail: "",
      ClientAdditionalMail: "",
      ClientPhone: "",
      ClientWorkPhone: "",
      ClientAdditionalPhone: "",
      ClientCity: "",
      ClientBirthday: "00.00.0000",
      ClientDocDate: "",
      ClientDocNumber: "",
      ClientDocSeries: "",
      ClientFioDiplom: "",
      ClientLevelDiplom: "",
      ClientNumberDiplom: "",
      ClientSeriesDiplom: "",
      ClientPol: "",
      ClientRegistrationNumber: "",
      ClientSnils: "",
      ClientInstitution: "",
      ClientCourseId: "",
      ClientGroupId: "",
      ClientProjectId: "",
      ClientPrice: "",
      ClientCategory: "",
      ClientStatus: "",
      ClientLogin: "",
      ClientPassword: "",
      inputChanged: false,
      courses: "",
      groups: "",
      projects: "",
      activeCourses: [],
      courses: [],
      commerce: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  getCommerce() {
    fetch(`https://student-control.nti-center.ru/api/commerce`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          // openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            commerce: response.data
          });
        }
      })
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/me/mycourses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          // openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            activeCourses: response.data
          });
        }
      })
  }


  getMe() {
    fetch(`https://student-control.nti-center.ru/api/me`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            ClientId: response.data[0].client_id,
            ClientName: response.data[0].client_name,
            ClientSurname: response.data[0].client_surname,
            ClientPatronymic: response.data[0].client_patronymic,
            ClientMail: response.data[0].client_mail,
            ClientWorkMail: response.data[0].client_work_mail,
            ClientAdditionalMail: response.data[0].client_additional_mail,
            ClientPhone: response.data[0].client_phone,
            ClientWorkPhone: response.data[0].client_work_phone,
            ClientAdditionalPhone: response.data[0].client_additional_phone,
            ClientCity: response.data[0].client_city,
            ClientBirthday: response.data[0].birthday,
            ClientDocDate: response.data[0].doc_date,
            ClientDocNumber: response.data[0].doc_number,
            ClientDocSeries: response.data[0].doc_series,
            ClientFioDiplom: response.data[0].fio_diplom,
            ClientLevelDiplom: response.data[0].level_diplom,
            ClientNumberDiplom: response.data[0].number_diplom,
            ClientSeriesDiplom: response.data[0].series_diplom,
            ClientPol: response.data[0].pol,
            ClientRegistrationNumber: response.data[0].registration_number,
            ClientSnils: response.data[0].snils,
            ClientInstitution: response.data[0].institution,
            ClientCourseId: response.data[0].course_id,
            ClientGroupId: response.data[0].group_id,
            ClientProjectId: response.data[0].project_id,
            ClientPrice: response.data[0].priceprice,
            ClientCategory: response.data[0].category,
            ClientStatus: response.data[0].client_status,
            ClientLogin: response.data[0].client_login,
          });
        }
      })
  }

  render() {

    return (
      <div>
        <NavBar />
        <div className="pageContent">
          {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="page_name">Мой профиль</div>
          </div> */}
          <div className={"profile_wrapper"} >
            <div className={"profile_short_data"} style={{}}>
              <Avatar size={window.innerWidth < 768 ? 100 : 200} shape="square" icon={<UserOutlined />} />
              <div>
                <h2>{this.state.ClientSurname + " " + this.state.ClientName + " " + this.state.ClientPatronymic} <Tooltip title="Если вы заметили неверные или не актуальные данные, обратитесь в службу поддержки"><InfoCircleOutlined style={{ cursor: 'pointer' }} /></Tooltip></h2>
                {this.state.ClientMail ? <div><MailOutlined /> {this.state.ClientMail}</div> : ""}
                {this.state.ClientWorkMail ? <div><MailOutlined /> {this.state.ClientWorkMail}</div> : ""}
                {this.state.ClientAdditionalMail ? <div><MailOutlined /> {this.state.ClientAdditionalMail}</div> : ""}
                {this.state.ClientPhone ? <div><PhoneOutlined /> {this.state.ClientPhone}</div> : ""}
                {this.state.ClientWorkPhone ? <div><PhoneOutlined /> {this.state.ClientWorkPhone}</div> : ""}
                {this.state.ClientAdditionalPhone ? <div><PhoneOutlined /> {this.state.ClientAdditionalPhone}</div> : ""}
              </div>
            </div>

            {this.state.activeCourses.length == 0 ?
              ""
              :
              <div className={"profile_courses"}>
                <div className="page_name">Доступные курсы</div>
                <div className="courses">
                  {this.state.activeCourses.map((course, index) => {
                    return (
                      <Card>
                        <div className="course_img" style={{ background: "url(/" + course.sc_image + ")" }}></div>
                        <div className="course_name">{course.sc_name}</div>
                        <div className="course_desc" dangerouslySetInnerHTML={{ __html: stateToHTML(convertFromRaw(JSON.parse(course.sc_desc))).substring(0, 90) == "" ? "" : stateToHTML(convertFromRaw(JSON.parse(course.sc_desc))).substring(0, 90) + "..." }}></div>
                        {
                          course.scg_status == 1 ?
                            <Link className="course_link" to={'/me/courses/' + course.sc_id}>Подробнее</Link>
                            :
                            <div>
                              {course.scg_date_start} - {course.scg_date_end}
                            </div>
                        }

                      </Card>
                    )
                  })}
                </div>
              </div>
            }

            {this.state.commerce.length == 0 ?
              ""
              :
              <div className={"profile_courses"}>
                <div className="page_name">Курсы по подписке</div>
                <div className="courses">
                  {this.state.commerce.map((course, index) => {
                    return (
                      <Card>
                        <div className="course_img" style={{ background: "url(/" + course.sc_image + ")" }}></div>
                        <div className="course_name">{course.sc_name}</div>
                        <div className="course_desc" dangerouslySetInnerHTML={{ __html: stateToHTML(convertFromRaw(JSON.parse(course.sc_desc))).substring(0, 90) == "" ? "" : stateToHTML(convertFromRaw(JSON.parse(course.sc_desc))).substring(0, 90) + "..." }}></div>
                        
                        <div>
                          {course.scb_start_date} - {course.scb_end_date}
                        </div>

                        <Link className="course_link" to={'/me/commerce/' + course.sc_id}>Подробнее</Link>
                      </Card>
                    )
                  })}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getMe();
    this.getCourses();
    this.getCommerce();
  }

}